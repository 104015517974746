<template>
  <div>
    <div style="position: absolute;right: 20px;z-index: 99">
      <xk-button type="primary" @click="submit">提交</xk-button>
      <xk-button type="info" @click="reset">重置</xk-button>
    </div>
    <el-tabs v-model="scrollName" @tab-click="handleScroll">
      <el-tab-pane v-for="(item, index) in scrollList" :key="index" :label="item.label" :name="item.name" />
    </el-tabs>
    <el-scrollbar style="height: calc(90vh - 200px)" wrap-style="overflow-x: hidden;">
      <div id="first">
        <div class="set-exam-title">满分设置</div>
        <el-table :data="data.scoreList" style="width: 100%">
          <el-table-column prop="examSubjectsName" label="科目">
            <template slot-scope="scope">
              <el-input placeholder="请输入" readonly v-model="scope.row.examSubjectsName"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="fullScore" label="满分设置">
            <template slot-scope="scope">
              <el-input oninput="value = value.replace(/[^\d.]/g,'')" placeholder="请输入" v-model="scope.row.fullScore"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="excellentPercentage" label="优秀分值(百分比)">
            <template slot-scope="scope">
              <el-input oninput="value = value.replace(/[^\d.]/g,'')" placeholder="请输入" v-model="scope.row.excellentPercentage"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="passPercentage" label="及格分值(百分比)">
            <template slot-scope="scope">
              <el-input oninput="value = value.replace(/[^\d.]/g,'')" placeholder="请输入" v-model="scope.row.passPercentage"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="lowPercentage" label="低分分值(百分比)低于该比例，视为低分">
            <template slot-scope="scope">
              <el-input oninput="value = value.replace(/[^\d.]/g,'')" placeholder="请输入" v-model="scope.row.lowPercentage"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div id="second" style="position: relative">
        <div class="set-exam-title">成绩等级设置</div>
<!--        <el-button style="position: absolute;right: 0;top: 0" @click="add">添加</el-button>-->
        <el-table :data="data.levelList" style="width: 100%">
          <el-table-column prop="levelName" label="等级名称">
            <template slot-scope="scope">
              <el-input placeholder="请输入" readonly v-model="scope.row.levelName"></el-input>
            </template>
          </el-table-column>
<!--          <el-table-column prop="previewName" label="预览">-->
<!--            <template slot-scope="scope">-->
<!--              <el-input placeholder="请输入" v-model="scope.row.previewName"></el-input>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column prop="lowPercentage" label="成绩最低分(百分比)">
            <template slot-scope="scope">
              <el-input oninput="value = value.replace(/[^\d.]/g,'')" placeholder="请输入" :readonly="scope.row.lowReadOnly" v-model="scope.row.lowPercentage"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="highPercentage" label="成绩最高分(百分比)">
            <template slot-scope="scope">
              <el-input oninput="value = value.replace(/[^\d.]/g,'')" placeholder="请输入" :readonly="scope.row.higthReadOnly" v-model="scope.row.highPercentage"></el-input>
            </template>
          </el-table-column>
<!--          <el-table-column label="操作" width="100">-->
<!--            <template slot-scope="scope">-->
<!--              <el-button type="text" size="small" @click="dl(scope.$index)">删除</el-button>-->
<!--            </template>-->
<!--          </el-table-column>-->
        </el-table>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: "examSetting",
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      scrollName: 'first',
      scrollList: [
        { label: '满分设置', name: 'first' },
        { label: '成绩等级设置', name: 'second' },
      ]
    }
  },
  methods: {
    handleScroll(tab) {
      this.$el.querySelector(`#${tab.name}`).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
    },
    submit() {
      this.$emit('submit', this.data)
    },
    reset() {
      this.$emit('reset')
    },
    dl(index) {
      this.$emit('dl', index)
    },
    add() {
      this.$emit('add')
    }
  }
}
</script>

<style lang="scss" scoped>
.set-exam-title {
  border-left: 2px solid #409EFF;
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0;
  padding-left: 10px;
}
/deep/ .el-input__inner {
  border: 0;
}
/deep/.el-input-number__increase {
  display: none;
}
/deep/.el-input-number__decrease {
  display: none;
}
/deep/ .el-table tbody tr:hover>td { background-color: #ffffff !important; }
</style>
